.expandable {
  border: 0 solid @border-light;
  border-width: 1px 0;

  & + .expandable {
    margin-top: -2px;
  }

  .expandable-label {
    padding: 1em 0;
    line-height: 1.5rem;
    cursor: pointer;

    .MuiSvgIcon-root {
      float: right;
      transition: transform .25s ease-in-out;
    }
  }

  .expandable-content {
    padding-bottom: 1em;
  }
}

.expandable-open > .expandable-label .MuiSvgIcon-root {
  transform: rotate(180deg);
}

.box-list {
  font-size: 21px;
  padding: 0;

  @media @generic-phone {
    font-size: 18px;
  }

  li {
    list-style: none;
  }

  a.box-item {
    background-color: white;
    color: @main-font-color;

    &.box-link:visited {
      color: @main-font-color;
    }
  }

  &.horizontal {
    &:not(.borderless) {
      border: 1px solid #d8d8d8;

      li {
        border-bottom: 1px solid #d8d8d8;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    a.box-item {
      display: flex;
      align-items: center;
      padding: @default-spacing;

      .title-right {
        margin-left: auto;
      }

      &.box-link {
        &:hover,
        &:focus {
          background-color: @light-background-color;
        }
      }
    }
  }

  .icon {
    width: @main-font-size;
    height: @bigger-font-size;
    margin-left: @tiny-spacing;
    vertical-align: middle;
  }
}

.empty-state {
  background: white;
  padding: 5em 8em;
  font-size: @main-font-size;
  text-align: center;

  &.margin-left-right {
    margin-left: @default-spacing;
    margin-right: @default-spacing;
  }

  &.small {
    padding: 2em 4em;
  }

  .main-icon .MuiSvgIcon-root {
    font-size: 48px;
  }

  p {
    margin: 2em auto;
    max-width: 800px;
  }

  a.link {
    text-decoration: underline;
  }

  .action {
    display: flex;
    justify-content: center;

    > * {
      margin: 0 @small-spacing;
    }

    @media @generic-phone {
      flex-direction: column;

      .MuiButton-root {
        width: 100%;

        &:not(:first-child) {
          margin-top: @small-spacing;
        }
      }

      > * {
        margin: 0;
      }
    }
  }

  @media @generic-phone {
    padding: 2em 3em;
  }

  @media @generic-phone-portrait {
    padding: 2em;

    p {
      margin: 2em 0;
    }
  }
}

.bib {
  font-weight: 700;
  border-color: @border-light;
  border-style: solid;
  border-width: 1px 0;
  padding: 2px;
  display: inline-block;
  white-space: nowrap;
}

.break-word {
  display: table;
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
}